// Customer specific overrides, define label in environment file

.kyyti {
    .navbar {
        background-color: rgba(255, 110, 0, 0.9);
    }
}

.kollibri {
    .navbar {
        background-color: rgba(0, 143, 209, 0.9);
    }
}

.sastamala {
    .navbar {
        background-color: rgba(150, 186, 51, 0.9);
    }
}

.eezery {
    $background-color: #1e214e;

    .navbar {
        background-color: $background-color;
    }

    // .side-panel, .shifts-panel, .detail-panel {
    //     background-color: rgba($background-color, $detail-panel-transparency);
    // }
}
