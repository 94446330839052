.messenger-panel {
    .es-input {
        input:first-child {
            width: 100%;
        }
    }
    .es-option {
        color: #000 !important;
    }

    .header-col-message {
        width: 50%;
    }
}

.messenger-link {
    color: inherit;
    cursor: pointer;
}
