.itinerary {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;

    .grid-row {
        display: flex;
        align-items: stretch;
        border-bottom: 1px lightgray solid;

        .td {
            padding: 3px;
            padding-top: 5px;
            padding-bottom: 5px;
            flex-basis: 0;
        }
    }

    .grid-heading {
        font-weight: bold;

        .td {
            position: relative;
        }
        &-spanning {
            position: absolute;
        }
    }

    // trip
    .grid-row {
        &.mod-shift,
        &.mod-break,
        &.mod-date {
            background-color: rgba(73, 81, 89, 0.6);
        }

        &.grid-row-highlighted {
            background-color: rgba(45, 45, 45, 0.72);
        }

        .time {
            flex-basis: 3.5em;
            text-align: right;
        }

        .timediff {
            text-align: left;
            flex-basis: 2.8em;
            overflow: hidden;
            overflow-wrap: normal;
            padding-left: 0px;
            padding-right: 0px;
        }

        .type {
            text-align: center;
            flex-basis: 25px;
            padding-left: 0px;
            padding-right: 0px;
        }

        .status {
            text-align: center;
            flex-basis: 25px;
            padding-left: 0px;
            padding-right: 0px;
        }

        .route {
            flex-grow: 1;
            width: 5em;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
        }

        .description {
            flex-grow: 2;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
        }

        .buttons {
            text-align: right;
            padding-left: 5px;
            padding-right: 5px;
            min-width: 4em;
        }
    }
}
