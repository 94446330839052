.hide {
    visibility: hidden;
}

.copy-with-calendar {
    display: inline-block;
}

.shifts-panel-container {
    padding: 0;
    // position: absolute;
    // top: 1em;
    // left: 1em;
    display: flex;
    flex-flow: column;
    height: 95%;
    width: 15%;
    min-width: 30em;

    border: 1px solid red;
}

.shifts-panel {
    z-index: 1;
    padding: 1em;
    border-radius: 0.5em;
    margin-bottom: 0.75em;
    background-color: rgba(darken($panel-background, 20%), $detail-panel-transparency);
    pointer-events: auto;
    font-size: $detail-panel-font-size;

    &:last-child {
        margin-bottom: 0;
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    td.remove-shift {
        min-width: 50px;
        vertical-align: middle;
    }

    td.edit-shift {
        min-width: 50px;
        vertical-align: middle;
    }

    form {
        padding: 1em;
    }

    .table-div {
        height: 700px;
        overflow-y: auto;
    }

    .is-driving {
        background-color: rgba(green, 0.45);
    }

    @keyframes fadeIt {
        0% {
            background-color: inherit;
        }
        30% {
            background-color: green;
        }
        100% {
            background-color: inherit;
        }
    }

    tr.is-new {
        animation-name: fadeIt;
        animation-duration: 2s;
        animation-iteration-count: 2;
    }
}
