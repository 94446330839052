.leaflet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.vehicleMarkerIcon {
    position: absolute;

    &::before {
        content: "";
        display: block;
        width: 25px;
        height: 41px;
        background: url("images/marker-icon.png") no-repeat center;
    }

    .icon-wrap {
        background-color: white;
        position: absolute;
        top: 5px;
        left: 6px;
        border-radius: 3px;
        color: black;
        font-size: 9.6px;
        padding: 1px;

        &.busy {
            background-color: #ff7070;
        }
    }

    .vehicle-number {
        position: absolute;
        top: -12px;
        left: 4px;
        display: block;
        background-color: white;
        padding-left: 1px;
        padding-right: 1px;
        font-size: 9px;
        min-width: 17px;
        text-align: center;
    }

    &.highlight {
        z-index: 800 !important;
        .vehicle-number {
            background-color: #4bfff6;
        }
    }
}
