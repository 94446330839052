@import 'ember-right-click-menu-default';

.ember-right-click-menu {
  list-style-type: none;
  padding: 0;
  border-radius: 0.4em;
  border: 1px solid #f5f5f5;
  background-color: white;
  color: #212121;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  white-space: nowrap;

  &__item {
    display: grid;
    grid-template-columns: 1fr max-content;
    font-size: 0.9rem;
    align-items: center;
    padding: 0.5em 1em;
    cursor: pointer;

    &:hover {
      background: #f5f5f5
    }

    &:first-child {
      border-radius: 0.5em 0.5em 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.5em 0.5em;
    }

    &--disabled {
      color: #E0E0E0;
      cursor: not-allowed;

      &:hover {
        background-color: initial;
      }
    }
  }
}
