.calendar-small {
    @include ember-power-calendar($cell-size: 30px);
}

button.ember-power-calendar-nav-control {
    &:focus {
        outline: 0px;
    }
}

.copy-shift-popup-content {
    border-radius: 0.5em;
}

.copy-shift-popup {
    border-radius: 0.5em;
    border: 1px solid;
    border-color: gray;
    padding: 0.3em;
    color: black;

    .card-header {
        font-weight: bolder;
        color: black;
    }

    .card-block {
        color: black;
    }
    .calendar-small {
        margin-bottom: 1em;
    }
}
