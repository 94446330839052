.settings-menu {
    display: inline-block;
    position: absolute;
    right: 0;
}

.navbar {
    pointer-events: auto;
    flex: 0 1 auto;
    z-index: 700;
    background: rgba(0, 0, 0, 0.72);
    border-radius: 0;

    .login-button {
        pointer-events: auto;
        position: absolute;
        right: 1em;
    }

    .navbar-brand {
        padding: 0.3125rem 0;
        vertical-align: top;
        display: inline-block;
        font-size: 1.25rem;
        line-height: inherit;
        color: white;
        font-weight: bold;

        img {
            float: left;
            max-height: 40px;
            margin: -0.3125rem 0.5rem -0.3125rem 0;
        }
    }
}

.dropdown-menu {
    position: absolute;
    right: 0;
    left: unset;
}

.nav-item .dropdown-menu {
    filter: invert(100%);

    .dropdown-item.active {
        background-color: $drp-item-background;
    }
}
