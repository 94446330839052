// constants, these need to be before import
$detail-panel-font-size: 0.8em;
$detail-panel-transparency: 0.72;
$panel-background: black;
//$vinka-background: #585858;
$vinka-background: black;
$drp-item-background: #d57be3;

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4";
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import "ember-basic-dropdown";
@import "ember-power-calendar";
@import "ember-right-click-menu";
@import "./common";
@import "./common-components";
@import "./nav-bar";
@import "./side-panel";
@import "./shifts-panel";
@import "./detail-panel";
@import "./vehicle-detail";
@import "./copy-shift-popup";
@import "./itinerary";
@import "./leaflet-map";
@import "./datarange-picker";
@import "./customers";
@import "./messenger";

html,
body,
body > .ember-view {
    height: 100%;
    width: 100%;
}

.login-page {
    .leaflet-tile {
        -webkit-filter: blur(5px);
        filter: url("blur.svg#blur");
        filter: blur(5px);
    }
}

.commander-notification {
    pointer-events: auto;
}

.vinka-containers {
    display: flex;
    height: 100%;
    flex-flow: row;
    justify-content: space-between;
}

.vinka-container {
    overflow-y: auto;
    max-height: 100%;
    padding: 0.5em;
    flex: 1 1 auto;
}

.vinka-panel-scroll {
    overflow: auto;
    max-height: 100%;
    z-index: 1;
    border-radius: 0.5em;
}

.middle-container {
    flex: 3 1 auto;
}

.vinka-viewport {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $vinka-background;
    display: flex;
    overflow: hidden;
    flex-flow: column;

    .vinka-content {
        overflow: hidden;
        flex: 1 1 auto;
    }
}

.fixed-table {
    table-layout: fixed;
}

th.sortable {
    cursor: pointer;
}

.search-box {
    background-color: transparent;
    border: 0px;
}

.ember-modal-overlay {
    z-index: 600;
}

.ember-modal-dialog {
    z-index: 600;
}

#myModal {
    z-index: 600;
}

.one-line-ellipsis {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.one-line-ellipsis-block {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.capacity-def {
    margin-right: 2px;
    display: inline-block;

    .capacity-count {
        display: inline-block;
    }
}

.capacity-text {
    border: solid;
    width: fit-content;
    padding-left: .1rem;
    padding-right: .1rem;
    border-width: .1rem;
    font-size: small;
    font-weight: bold;
    border-radius: 25%;
    display: inline-block;
}


// TODO: fixing Bootstrap ALPHA.5 text color problem in side panels
.card {
    color: white;
    .table {
        color: white;
    }
}

.copy-shift-popup {
    .card {
        color: black;
    }
}

.red {
    color: orangered;
}

.animation-pulse {
    animation: pulseAnimation ease-in-out 0.5s infinite alternate;
}

@keyframes pulseAnimation {
    from {
        opacity: 0.5;
    }
}

.x-select-smaller {
    .es-control {
        height: 31px;
    }
}

.attribute-border {
    border: solid;
    border-width: thin;
    border-radius: 10%;
}

.attribute-text {
    border: solid;
    width: fit-content;
    padding-left: .1rem;
    padding-right: .1rem;
    border-width: .1rem;
    font-size: small;
    font-weight: bold;
    border-radius: 25%;
    display: inline-block;
}

