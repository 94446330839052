.left-panel-container {
    padding: 0;
    // position: absolute;
    // top: 1em;
    // left: 1em;
    display: flex;
    flex-flow: column;
    // height: 95%;
    width: 15%;
    min-width: 30em;

    // border: 1px solid red;

    pointer-events: auto; // makes the map unzoomable from the left side

    .scroll-helper {
        z-index: 1000; // so that scroll event doesn't go through
    }
}

.detail-panel {
    padding: 1em;
    border-radius: 0.5em;
    margin-bottom: 0.75em;
    background-color: rgba(darken($panel-background, 20%), $detail-panel-transparency);
    pointer-events: auto;
    font-size: $detail-panel-font-size;
    flex-shrink: 0;
    // min-height: 0;

    &:last-child {
        margin-bottom: 0;
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .capacity-def {
        margin-right: 2px;
    }

    .timetable {
        width: 99%;
        th {
            border-bottom: 2px solid #eceeef;
        }
    }

    .detail-property-row {
        margin-left: 0;
        margin-right: 0;

        &:hover {
            background-color: rgba(73, 81, 89, 0.6);
        }

        span:first-child {
            padding-left: 0;
        }
        span:last-child {
            padding-right: 0;
        }
    }

    .is-hidden {
        display: none;
    }

    .spoiler {
        .spoiler-trigger {
            display: inline-block;
            text-decoration: underline;
            color: #ffffff;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }

        .spoiler-collapsed {
            display: none;
        }

        &.collapsed {
            .spoiler-expanded {
                display: none;
            }
            .spoiler-collapsed {
                display: inline-block;
            }
        }
    }

    .unassign-vehicle {
        display: inline-block;
        margin-left: 5px;
        color: orangered;
    }

    .card-subtitle {
        &:not(.collapsed) {
            .collapsed-icon {
                display: none;
            }
        }

        &.collapsed {
            .expanded-icon {
                display: none;
            }
        }
    }
}
