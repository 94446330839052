.center-text {
    text-align: center;
}

.plain-text {
    font-weight: normal;
}

.secondary-text {
    font-size: 0.85em;
}

.cutting-text {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%; /* matches parent width, parent needs to be block elem */
}

.vinka-flex-column {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    max-height: 100%;

    .vinka-flex-column-head {
        flex-shrink: 0;
    }

    .vinka-flex-column-body {
        overflow: auto;
    }
}

.vinka-max-50vh {
    max-height: 50vh;
}

.vinka-dropdown-content {
    max-height: 200px;
    overflow: auto;

    .vinka-dropdown-row {
        &:hover {
            background-color: rgba(73, 81, 89, 0.6);
            color: #ffffff;
        }
    }
}

$fixed-width-values: (
    "4": 4%,
    "6": 6%,
    "8": 8%,
    "10": 10%,
    "15": 15%,
    "16": 16%,
    "20": 20%,
    "35": 35%,
);
@each $name, $width in $fixed-width-values {
    .vinka-w#{$name}p {
        width: $width !important;
        min-width: $width !important;
        max-width: $width !important;
    }
}

.ember-table.table {
    margin-bottom: 0;
}

.ember-table-overflow > table {
    width: 100%;

    > thead > tr > th {
        overflow: hidden;
        background-color: #2d2d2d;
        text-overflow: ellipsis;
        white-space: nowrap;

        > .text-gray-500 {
            float: right;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 1);
}
