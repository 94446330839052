$drp-item-background: #d57be3;

.liveText {
    color: #04c404;
    font-weight: bold;
}

.daterange-picker-spinner {
    color: white;
}

.date-range-picker {
    background: transparent;
    float: right;

    > div {
        float: left;
    }

    .label {
        cursor: pointer;
        color: white;
    }

    .move-in-time {
        width: 24px;
        cursor: pointer;
        color: white;
        text-align: center;
    }

    .dropdown {
        display: flex;
        background: #000000;
        pointer-events: all;
        padding: 1em;
        border-radius: 0.5em;

        .picker {
            padding-right: 20px;

            .buttons {
                margin-top: 14px;
            }

            .inputs {
                display: flex;
                max-width: 308px;
            }

            .form-control.flatpickr-wrapper {
                padding: 0;

                .flatpickr-input {
                    display: block;
                    width: 100%;
                    padding: 0.25rem 0.5rem;
                    border: 0;
                    font-size: 0.875rem;
                    line-height: 1.5;
                }

                .flatpickr-calendar.hasTime.noCalendar {
                    max-width: 154px;
                }
            }

            // make the main calendar black
            .calendar {
                > .flatpickr-input {
                    display: none !important;
                }

                .flatpickr-calendar {
                    border: 1px solid #6c757d;
                    background: #000000;
                    box-shadow: none;
                }
                .flatpickr-day {
                    color: #fff;

                    &:hover {
                        color: #000;
                    }

                    &.inRange {
                        color: #000;
                    }

                    &.selected {
                        color: #fff;
                    }
                }
                .flatpickr-time {
                    input {
                        color: #fff;

                        &:hover,
                        &:focus {
                            background: transparent;
                        }
                    }
                }
                .flatpickr-time-separator {
                    color: #fff;
                }
                .flatpickr-current-month {
                    input.cur-year {
                        color: #fff;
                    }
                }
                .flatpickr-monthDropdown-months {
                    color: #fff;
                    background: #000;
                }
                .flatpickr-months svg path {
                    stroke: #fff;
                }
                .flatpickr-time .numInputWrapper span.arrowUp:after {
                    border-bottom-color: #ffffff;
                }
                .flatpickr-time .numInputWrapper span.arrowDown:after {
                    border-top-color: #ffffff;
                }
                .flatpickr-weekday {
                    color: rgba(255, 255, 255, 0.54);
                }
            }
        }

        .options {
            .option {
                display: block;
                margin-bottom: 8px;
                padding: 3px 12px;
                border-radius: 4px;
                color: #ffffff;
                font-size: 13px;
                white-space: nowrap;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: #2a841c;
                    color: #000;
                }
            }
        }
    }
}
