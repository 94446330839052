.vehicle-detail {
    .header-row {
        h6 {
            float: left;
        }

        .header-row-action {
            float: right;
        }
        .clear {
            clear: both;
        }
    }

    .shift-row-buttons {
        .left {
            float: left;
        }

        .right {
            float: right;
        }
    }

    td.remove-shift {
        min-width: 50px;
        vertical-align: middle;
    }

    .break-row-buttons {
        .left {
            float: left;
        }

        .right {
            float: right;
        }
    }

    .break-duration-input {
        width: 3em;
        height: 2em;
    }

    .break-duration-td {
        vertical-align: middle;
    }

    td.remove-break {
        min-width: 50px;
        vertical-align: middle;
    }

    .is-driving {
        background-color: rgba(green, 0.45);
    }

    .is-on-break {
        background-color: rgba(green, 0.45);
    }
}
